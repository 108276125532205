// 绘画
const aippt = {
	pptTheme: {
		url: "/addons/chatgpt/Ppt/get_pptTheme",
		method: "POST",
		desc: "获取ppt主题"
	},
	pptOutline: {
		url: "/addons/chatgpt/Ppt/get_ppt_outline",
		method: "POST",
		desc: "获取ppt大纲"
	},
	pptMbList: {
		url: "/addons/chatgpt/Ppt/get_template_list",
		method: "POST",
		desc: "获取ppt的模板"
	},
	generatePppt: {
		url: "/addons/chatgpt/Ppt/generate_ppt",
		method: "POST",
		desc: "生成ppt"
	},
	getuserpptlist: {
		url: "/addons/chatgpt/Ppt/get_user_ppt_list",
		method: "POST",
		desc: "获取ppt列表"
	},
	deluserppt: {
		url: "/addons/chatgpt/Ppt/del_user_ppt",
		method: "POST",
		desc: "删除某个ppt"
	},
	
}

export default aippt
